
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  isRestClientError,
  JpuDTO,
  PaymentType,
  storeErrorsMapping,
  SubscriptionActionRequest,
  SubscriptionDTO,
} from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription, PlanContent } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration } from "@/types/services/menus";
import LoadingMask from "@/components/common/LoadingMask.vue";
import AnimatedContentCard from "@/components/content/AnimatedContentCard.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";
import { AnimatedContentCardProps } from "@/types/content/animatedContentCard";
import { ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";

@Component({
  name: "SubscriptionsAddXrayPremium",
  components: {
    PageView,
    PageSection,
    LoadingMask,
    AnimatedContentCard,
    SimpleIconTitle,
  },
})
export default class SubscriptionsAddXrayPremium extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  @Getter("menuConfiguration", { namespace: "application" })
  menuConfiguration!: MenuConfiguration;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  accountNumber!: FlatSubscription["accountNumber"];
  subscription: FlatSubscription | null = null;
  price: string | null = null;
  contentCards: AnimatedContentCardProps[] = [];
  videoHeight = 200;
  contentHeight: number = 100;
  contentHeightUnit: "px" | "%" = "%";
  loading = false;
  cardsDataRow1: Array<ContentNode> = [];
  cardsDataRow2: Array<ContentNode> = [];

  @Action("setSubscriptions", { namespace: "subscriptions" })
  setSubscriptions!: (subscriptions: SubscriptionDTO[]) => void;

  @Watch("subscription")
  async onSubscriptionChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }

  get actionName() {
    if (this.subscription && this.subscription.meta.isPrepaidPayment) {
      return "Contact Us";
    }
    if (this.subscription && this.subscription.meta.isEnterpriseTeam) {
      return "Add to My Subscription";
    }
    return "Upgrade to Enterprise";
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.subscription
      ? { subscriptionMetas: this.subscriptions.map(s => s.meta), currentSubscriptionMeta: this.subscription.meta }
      : {};
  }
  get nbCardsPerRows() {
    return this.isMobile ? 1 : this.isTablet ? 2 : 3;
  }

  get isEnterprise() {
    return this.subscription && this.subscription.meta.isEnterpriseTeam;
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
  get isMobile() {
    return this.$mq === "mobile";
  }
  get isLaptop() {
    return this.$mq === "laptop";
  }
  get isLaptopXL() {
    return this.$mq === "laptopXL";
  }

  get titleWrapperStyles() {
    // const flexVal: number = this.isLaptop ? 1.7 : this.isLaptopXL ? 1.3 : this.isMobile ? 0 : 1;
    const flexVal: number = this.isLaptop ? 1.5 : this.isTablet ? 1.8 : this.isMobile ? 0 : 1;
    return {
      flex: flexVal,
    };
  }

  get sectionClasses() {
    const classes = [];
    classes.push((!this.isMobile && "noscroll") || "");
    return classes;
  }
  get contentWrapperClasses() {
    const classes = [];
    classes.push((!this.isMobile && "scrollable") || "");
    return classes;
  }

  get contentWrapperStyles() {
    return {
      height: this.contentHeight + this.contentHeightUnit,
    };
  }

  get cardStyles() {
    const flexPercent = 100 / this.nbCardsPerRows;
    return {
      flex: flexPercent + "%",
    };
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading() {
    return !this.price || !this.subscription || this.loading;
  }

  get serverName() {
    return (this.subscription && this.subscription.serverName) || "";
  }

  get subTitleContent(): PlanContent[] {
    return [
      {
        title: "Open Source License Checks",
        texts: [],
        icon: { name: "icon-mjf-curved-check", cssClass: "mjf-light-gray" },
      },
      {
        title: "Premium Vulnerability Database VulnDB included",
        texts: [],
        icon: { name: "icon-mjf-curved-check", cssClass: "mjf-light-gray" },
      },
      {
        title: "SCIM ID Management Support",
        texts: [],
        icon: { name: "icon-mjf-curved-check", cssClass: "mjf-light-gray" },
      },
      {
        title: "3rd-party Vault Integration",
        texts: [],
        icon: { name: "icon-mjf-curved-check", cssClass: "mjf-light-gray" },
      },
      {
        title: "AWS PrivateLink Support",
        texts: [],
        icon: { name: "icon-mjf-curved-check", cssClass: "mjf-light-gray" },
      },
    ];
  }

  contentIconClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.icon) {
      classes.push(content.icon.name);
      if (content.icon.fontSize) {
        classes.push(`fs-${content.icon.fontSize}`);
      }
      if (content.icon.cssClass) {
        classes.push(content.icon.cssClass);
      }
    }
    return classes;
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscription) {
      const meta = this.subscription.meta;
      return await this.$jfMenus.defineContextualMenuConfiguration(
        this.serverName,
        meta,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  notFound() {
    this.$router.replace({ path: "/404" });
  }

  notAllowed() {
    this.$router.replace({ path: "/" });
  }

  async loadContent() {
    // we need subscription before price to calculate right price.
    await this.fetchSubscription();
    await this.loadStartingPrice();
    this.contentHeight = (document.getElementsByClassName("ex-page-wrapper")[0] as HTMLDivElement).clientHeight;
    this.contentHeightUnit = "px";
  }

  async loadStartingPrice() {
    const types = await this.$jfSubscriptions.getSubscriptionTypes(this.accountNumber);
    const enterpriseMonthly = types.find(type => type.paymentType === "ENTERPRISE_MONTHLY");
    const enterpriseXMonthly = types.find(type => type.paymentType === "ENTERPRISE_X_MONTHLY");

    if (enterpriseMonthly && enterpriseXMonthly) {
      this.price = this.$utils.round(parseInt(enterpriseXMonthly.price) - parseInt(enterpriseMonthly.price), 0);
    } else {
      this.$log.error(`error in subscriptionTypes, ${enterpriseMonthly} ${enterpriseXMonthly}`);
    }
  }

  async fetchSubscription() {
    try {
      const subscription = await this.$jfSubscriptions.getSubscription(this.accountNumber);
      const meta = subscription.meta;
      if (!this.$jfSubscriptions.isAddSecurityPremiumPageReachable(meta)) {
        return this.notAllowed();
      }
      this.subscription = this.$jfSubscriptions.transformSubscription(subscription);
    } catch (error) {
      if (error.httpStatusText === 500) {
        this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong });
      } else {
        this.notFound();
      }
    }
  }

  async sendSubscriptionRequest(serverName: JpuDTO["serverName"], reason: SubscriptionActionRequest["reason"]) {
    await this.$jfSubscriptions.sendSubscriptionActionRequest({
      marketoCookie: this.$jfMarketo.getCookie(),
      actionType: "upgrade",
      serverName: serverName,
      reason: reason,
    });
  }

  handleUpgradeClick() {
    if (!this.subscription) {
      return;
    }
    if (this.subscription.meta.isPrepaidPayment) {
      this.sendSubscriptionRequest(this.serverName, "account_is_prepaid");
      this.$jfNotification.success({ text: this.$jfMessages.subscriptions_request_getaquote_success });
      return;
    }
    if (this.subscription.meta.isProTeam || this.subscription.meta.isFree) {
      this.$router.push({ path: "upgrade" });
      return;
    }
    this.$jfModal.confirm({
      body: `<div text-left>Add <strong>Security Pack</strong> to my subscription, +$${this.price}/month</div>`,
      buttonsText: {
        confirm: "Confirm",
      },
      onConfirm: () => {
        this.addPremiumXray();
        return true;
      },
      title: "JFrog Security Pack",
      headerBorder: false,
      displayCancelButton: false,
      footerBorder: false,
    });
  }

  async addPremiumXray() {
    if (!this.subscription) {
      return;
    }
    try {
      this.loading = true;
      const subscriptionDTO = await this.$jfSubscriptions.upgradeSubscription(
        this.subscription.accountNumber,
        this.subscription.paymentType as PaymentType,
        "ENTERPRISE_X_MONTHLY",
        this.subscription.serverName,
      );

      await this.setSubscriptions(
        this.subscriptions.map(subscription =>
          subscriptionDTO.accountNumber === subscription.accountNumber ? subscriptionDTO : subscription,
        ),
      );

      this.$jfNotification.success({ text: this.$jfMessages.subscriptions_add_premium_xray_success });
      this.$router.push({ path: `/subscriptions`, params: { accountNumber: "" + this.subscription.accountNumber } });
    } catch (e) {
      this.$log.error(e);
      let errorMessage = this.$jfMessages.subscriptions_upgrade_error_unknown;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.upgradeSubscription.narcissusTaskInProgress) {
          errorMessage = this.$jfMessages.subscriptions_narcissus_task_in_progress;
        } else {
          errorMessage = this.$jfMessages.generateMailLink(e.httpBody.message || (e.httpStatusText as string));
        }
      }
      this.notifyError(errorMessage);
    } finally {
      this.loading = false;
      this.$emit("loading", false);
    }
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  mounted() {
    this.$jfModal.closeAll();
    const queryParam = parseInt(this.$route.params.accountNumber) as SubscriptionDTO["accountNumber"];
    if (Number.isNaN(queryParam)) {
      this.notFound();
    } else {
      this.accountNumber = queryParam;
      this.loadContent();
    }

    this.cardsDataRow1 = [
      {
        title: "Premium Vulnerability & Compliance Intelligence",
        value:
          "Gain confidence in your releases with the most timely and comprehensive vulnerability intelligence database VulnDB, coupled with other public and private metadata sources.",
        icon: "xray1.png",
      },
      {
        title: "Governance with License Compliance",
        value:
          "Ensure your releases are free from open source software license compliance issues. Set up policies to automate your open source software license compliance.",
        icon: "xray2.png",
      },
      {
        title: "System for Cross-domain Identity Management (SCIM) 2.0",
        value:
          "Automate the managing of access rights and permissions of users who are joining, changing, or leaving roles and teams. Utilize the user management tools you are likely using already, like Active Directory or Okta or another ID management tool that support SCIM 2.0.",
        icon: "xray3.png",
      },
    ];

    this.cardsDataRow2 = [
      {
        title: "HashiCorp Vault Integration",
        value:
          "Establish an external Hashicorp Vault integration with your JFrog Platform Deployment. Manage secrets such as signing keys from a centrally managed vault. The Platform knows to associate with or grab the relevant keys or secrets from the vault. It supports multiple signing key types such as GPG or RSA used to sign packages or release bundles.",
        icon: "xray4.png",
      },
      {
        title: "Private Endpoints",
        value:
          "(Currently Private Endpoints is AWS only)<br/> Secure your network traffic with private connectivity between your instances and services. Easily establish a secure network connection (with AWS PrivateLink) originating from your AWS Virtual Private Cloud (VPC) to your JFrog AWS Cloud (SaaS) instance and your on-premises networks - without sending traffic through the public Internet. ",
        icon: "xray5.png",
      },
    ];
  }
}
