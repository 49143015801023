
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PageSection",
  components: {},
})
export default class PageSection extends Vue {
  @Prop({ default: undefined })
  private backgroundColor!: string;
  @Prop({ default: false })
  private backgroundGray!: boolean;
  @Prop({ default: () => [] })
  private sectionClasses!: string[];

  get pageSectionClasses(): string[] {
    let classes = ["section-wrapper", this.$mq];
    if (!this.backgroundColor) {
      classes.push(this.backgroundGray ? "bg-gray" : "bg-default");
    }
    return classes.concat(this.sectionClasses);
  }

  get pageSectionStyles(): {} {
    let styles: { [key: string]: any } = {};
    if (this.backgroundColor) {
      styles["backgroundColor"] = this.backgroundColor;
    }
    return styles;
  }
}
