
import { Component, Prop, Vue } from "vue-property-decorator";
import { AnimatedContentCardProps } from "@/types/content/animatedContentCard";

@Component({
  name: "AnimatedContentCard",
  components: {},
})
export default class AnimatedContentCard extends Vue {
  @Prop() private title!: AnimatedContentCardProps["title"];
  @Prop() private content!: AnimatedContentCardProps["content"];
  @Prop() private videoUrl!: AnimatedContentCardProps["videoUrl"];
  @Prop() private videoPoster!: AnimatedContentCardProps["videoPoster"];
  @Prop() private width!: AnimatedContentCardProps["width"];
  @Prop() private height!: AnimatedContentCardProps["height"];
  @Prop() private isPremium!: AnimatedContentCardProps["height"];
}
