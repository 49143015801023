
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "PageView",
  components: {},
})
export default class PageView extends Vue {
  @Prop({ default: undefined })
  private titleBackgroundColor!: string;
  @Prop({ default: undefined })
  private contentBackgroundColor!: string;
  get hasTitle(): boolean {
    if (this.$slots.title) {
      return this.$slots.title.length > 0;
    }
    return false;
  }

  get titleWrapperClasses(): string[] {
    let classes = ["view-title-wrapper"];
    if (!this.titleBackgroundColor) {
      classes.push("bg-default");
    }
    return classes;
  }

  get contentWrapperClasses(): string[] {
    let classes = ["view-content-wrapper"];
    if (!this.contentBackgroundColor) {
      classes.push("bg-default");
    }
    if (this.hasTitle) {
      classes.push("has-title");
    }
    return classes;
  }

  get titleWrapperStyles(): {} {
    let styles: { [key: string]: any } = {};
    if (this.titleBackgroundColor) {
      styles["backgroundColor"] = this.titleBackgroundColor;
    }
    return styles;
  }

  get contentWrapperStyles(): {} {
    let styles: { [key: string]: any } = {};
    if (this.contentBackgroundColor) {
      styles["backgroundColor"] = this.contentBackgroundColor;
    }
    return styles;
  }
}
